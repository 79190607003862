import { AppBar } from "@material-ui/core"
import Toolbar from "@material-ui/core/Toolbar"
import useScrollTrigger from "@material-ui/core/useScrollTrigger"
import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import Logo from "../../images/logo.jpg"
import { heightTransition } from "../../theme/globalStyles"
import Contact from "./contact"
import DesktopMenu from "./desktopMenu"
import MobileMenu from "./mobileMenu"
import Spacer from "./spacer"

interface NavBarProps {
  trigger: boolean
  children: any | any[]
}

const NavBar = styled(({ trigger, ...rest }: NavBarProps) => (
  <Toolbar {...rest} />
))`
  height: ${({ theme, trigger }) =>
    trigger ? theme.spacing(18) : theme.spacing(10)}px;
  display: flex;
  align-items: center;
  ${heightTransition}

  .logo {
    height: ${({ trigger }) => (trigger ? 90 : 55)}px;
    ${heightTransition}

    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
      height: ${({ trigger }) => (trigger ? 80 : 45)}px;
    }
  }
`

const NavWrapper = styled(AppBar)`
  background-color: ${({ theme }) => theme.palette.common.white};
  color: ${({ theme }) => theme.palette.primary.main};
`

const HomeLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Header: React.FC = () => {
  const trigger = useScrollTrigger({
    threshold: 75,
    disableHysteresis: true,
  })

  return (
    <>
      <NavWrapper position="fixed" elevation={1}>
        <Contact />
        <NavBar trigger={!trigger}>
          <HomeLink to="/">
            <img src={Logo} alt="logo" className="logo" />
          </HomeLink>
          <DesktopMenu trigger={!trigger} />
          <MobileMenu />
        </NavBar>
      </NavWrapper>
      <Spacer trigger={!trigger} />
    </>
  )
}

export default Header
