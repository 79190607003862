import Link from "@material-ui/core/Link"
import Typography from "@material-ui/core/Typography"
import React from "react"

interface Props {
  color?:
    | "initial"
    | "inherit"
    | "primary"
    | "secondary"
    | "textPrimary"
    | "textSecondary"
    | "error"
}

const MDDDLink: React.FC<Props> = ({ color = "secondary" }) => (
  <Typography variant="caption">
    <Link
      href="https://www.mddd.nl"
      rel="author noreferrer noopener"
      target="_blank"
      color={color}
    >
      Built by MDDD © {new Date().getFullYear()}
    </Link>
  </Typography>
)

export default MDDDLink
