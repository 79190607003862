import IconButton from "@material-ui/core/IconButton"
import FacebookIcon from "@material-ui/icons/Facebook"
import InstagramIcon from "@material-ui/icons/Instagram"
import LinkedInIcon from "@material-ui/icons/LinkedIn"
import PinterestIcon from "@material-ui/icons/Pinterest"
import React from "react"
import useContactInfo from "../../hooks/useContactInfo"

interface ButtonProps {
  href: string
}

const SocialButton: React.FC<ButtonProps> = ({ href, children }) => (
  <IconButton
    color="primary"
    component="a"
    href={href}
    children={children}
    target="_blank"
    rel="noopener noreffer"
  />
)

const SocialMenu: React.FC = () => {
  const { facebook, pinterest, linkedin, instagram } = {
    ...(useContactInfo() || {}),
  }

  return (
    <div>
      {facebook ? (
        <SocialButton href={facebook}>
          <FacebookIcon />
        </SocialButton>
      ) : null}
      {pinterest ? (
        <SocialButton href={pinterest}>
          <PinterestIcon />
        </SocialButton>
      ) : null}
      {linkedin ? (
        <SocialButton href={linkedin}>
          <LinkedInIcon />
        </SocialButton>
      ) : null}
      {instagram ? (
        <SocialButton href={instagram}>
          <InstagramIcon />
        </SocialButton>
      ) : null}
    </div>
  )
}

export default SocialMenu
