import { graphql, useStaticQuery } from "gatsby"
import { FooterMenuQuery } from "./__generated__/FooterMenuQuery"

const useFooterMenu = () => {
  const { wpMenu } = useStaticQuery<FooterMenuQuery>(graphql`
    query FooterMenuQuery {
      wpMenu(locations: { eq: FOOTER_MENU }) {
        count
        menuItems {
          nodes {
            ...MenuItem
          }
        }
      }
    }
  `)

  const menu = wpMenu?.menuItems?.nodes

  return menu
}

export default useFooterMenu
