import styled from "styled-components"

export type SpacerProps = {
  minHeight?: number
}
const Spacer = styled.div<SpacerProps>`
  min-height: ${({ minHeight, theme }) =>
    minHeight ? minHeight : theme.mixins.toolbar.minHeight}px;
`

export default Spacer
