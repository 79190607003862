import Button, { ButtonProps } from "@material-ui/core/Button"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Divider from "@material-ui/core/Divider"
import Grow from "@material-ui/core/Grow"
import MenuItem, { MenuItemProps } from "@material-ui/core/MenuItem"
import MenuList from "@material-ui/core/MenuList"
import Paper from "@material-ui/core/Paper"
import Popper from "@material-ui/core/Popper"
import useScrollTrigger from "@material-ui/core/useScrollTrigger"
import Chevron from "@material-ui/icons/KeyboardArrowUpRounded"
import { Link } from "gatsby"
import React, { forwardRef, useEffect, useRef } from "react"
import styled from "styled-components"
import useBoolState from "../../hooks/useBoolState"
import useMainMenu from "../../hooks/useMainMenu"
import { MainMenuQuery_wpMenu_menuItems_nodes_childItems_nodes } from "../../hooks/__generated__/MainMenuQuery"

interface MenuProps {
  trigger: boolean
}

interface MenuBTNProps extends MenuItemProps {
  trigger: boolean
}

interface SingleMenuProps extends MenuProps {
  url: string
  style?: {}
}

interface MultipleMenuProps extends SingleMenuProps {
  listItems:
    | (MainMenuQuery_wpMenu_menuItems_nodes_childItems_nodes | null)[]
    | null
}

interface BtnProps extends ButtonProps {
  trigger: boolean
  to: string
}

interface EndProps {
  open: boolean
}

const MenuWrapper = styled.nav`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
    display: none !important;
  }
`

const Btn = styled(({ trigger, ...props }) => (
  <Button color="primary" component={Link} {...props} />
))<BtnProps>`
  margin: 0 ${({ theme }) => theme.spacing(3)}px;
  font-size: ${({ trigger }) => (trigger ? "130%" : "100%")};
  letter-spacing: 0.2rem;
  transition: font-size ${({ theme }) => theme.transitions.duration.complex}ms
    ease-in-out;

  .MuiButton-endIcon {
    margin: 0;
    svg {
      font-size: 130%;
    }
  }
`

const BtnWithRef = forwardRef<HTMLInputElement, BtnProps>((props, ref) => (
  <Btn {...props} innerRef={ref} />
))

const MenuBTN = styled(({ trigger, ...props }) => (
  <MenuItem component={Link} {...props} />
))<MenuBTNProps>`
  padding: ${({ theme }) => `${theme.spacing(2)}px ${theme.spacing(4)}px`};
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: ${({ trigger }) => (trigger ? "115%" : "95%")};
  letter-spacing: 0.1rem;
  text-align: center;
  width: 100%;
`

const End = styled(Chevron)<EndProps>`
  transform: ${({ open }) => (open ? "rotate(180deg)" : "rotate(0deg)")};
  transition: transform ${({ theme }) => theme.transitions.duration.short}ms
    ease-in-out;
`

const SingleMenuButton: React.FC<SingleMenuProps> = ({
  children,
  url,
  style = {},
  trigger,
}) => (
  <Btn to={url} style={style} trigger={trigger}>
    {children}
  </Btn>
)

const MultipleMenuButton: React.FC<MultipleMenuProps> = ({
  children,
  url,
  style = {},
  trigger,
  listItems,
}) => {
  const [open, { handleOpen, handleClose, toggle }] = useBoolState()
  const ref = useRef(null)
  const scrolled = useScrollTrigger({ threshold: 1 })

  useEffect(() => {
    handleClose()
  }, [scrolled])

  return (
    <>
      <BtnWithRef
        to={url}
        style={style}
        trigger={trigger}
        endIcon={<End open={open} />}
        ref={ref}
        onMouseEnter={handleOpen}
        onClick={toggle}
      >
        {children}
      </BtnWithRef>
      <Popper open={open} transition disablePortal anchorEl={ref.current}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList onMouseLeave={handleClose}>
                  {listItems?.map((item, i) => [
                    <MenuBTN
                      key={item?.id}
                      to={String(item?.path)}
                      onClick={handleClose}
                      trigger={trigger}
                    >
                      {String(item?.label)}
                    </MenuBTN>,
                    i < listItems.length - 1 ? <Divider /> : null,
                  ])}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

const DesktopMenu: React.FC<MenuProps> = ({ trigger }) => {
  const menu = useMainMenu()

  return (
    <MenuWrapper>
      {menu?.map((menuItem, i) =>
        menuItem?.childItems?.nodes &&
        menuItem?.childItems?.nodes?.length > 0 ? (
          <MultipleMenuButton
            trigger={trigger}
            key={menuItem?.id}
            url={menuItem?.path || "/"}
            style={{ ...(i === menu.length - 1 ? { marginRight: 0 } : {}) }}
            listItems={menuItem.childItems.nodes}
          >
            {menuItem?.label}
          </MultipleMenuButton>
        ) : (
          <SingleMenuButton
            trigger={trigger}
            key={menuItem?.id}
            url={menuItem?.path || "/"}
            style={{ ...(i === menu.length - 1 ? { marginRight: 0 } : {}) }}
          >
            {menuItem?.label}
          </SingleMenuButton>
        )
      )}
    </MenuWrapper>
  )
}

export default DesktopMenu
