import { graphql, useStaticQuery } from "gatsby"
import { MainMenuQuery } from "./__generated__/MainMenuQuery"

const useMainMenu = () => {
  const { wpMenu } = useStaticQuery<MainMenuQuery>(graphql`
    fragment MenuItem on WpMenuItem {
      id
      label
      path
    }
    query MainMenuQuery {
      wpMenu(locations: { eq: MAIN_MENU }) {
        count
        menuItems {
          nodes {
            ...MenuItem
            parentId
            childItems {
              nodes {
                ...MenuItem
              }
            }
          }
        }
      }
    }
  `)

  const menu = wpMenu?.menuItems?.nodes?.filter(menuItem => !menuItem?.parentId)

  return menu
}

export default useMainMenu
