import { graphql, useStaticQuery } from "gatsby"
import parsePhoneNumber from "libphonenumber-js"
import { ContactDataQuery } from "./__generated__/ContactDataQuery"

const useContactInfo = () => {
  const { wpPage } = useStaticQuery<ContactDataQuery>(graphql`
    query ContactDataQuery {
      wpPage(databaseId: { eq: 15 }) {
        contactFields {
          email
          number
          naw
          extra
          facebook
          instagram
          linkedin
          pinterest
        }
      }
    }
  `)

  const phone = parsePhoneNumber(String(wpPage?.contactFields?.number), "NL")
  const phoneUri = phone?.getURI()
  const nicePhone = phone?.formatNational()

  const emailUri = `mailto:${wpPage?.contactFields?.email}`

  return {
    ...wpPage?.contactFields,
    formatted: {
      number: {
        phoneUri,
        nicePhone,
      },
      email: {
        emailUri,
      },
    },
  }
}

export default useContactInfo
