import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import Link from "@material-ui/core/Link"
import MenuItem from "@material-ui/core/MenuItem"
import MenuList from "@material-ui/core/MenuList"
import Modal from "@material-ui/core/Modal"
import Slide from "@material-ui/core/Slide"
import CloseRoundedIcon from "@material-ui/icons/CloseRounded"
import MenuRoundedIcon from "@material-ui/icons/MenuRounded"
import { Link as GatsbyLink } from "gatsby"
import React from "react"
import styled from "styled-components"
import useBoolState from "../../hooks/useBoolState"
import useContactInfo from "../../hooks/useContactInfo"
import useMainMenu from "../../hooks/useMainMenu"
import Logo from "../../images/logo.jpg"
import Center from "../base/layout/center"
import Spacer from "../base/layout/spacer"
import MDDDLink from "../base/mdddLink"

const MenuButton = styled(IconButton)`
  margin-left: auto;
  font-size: ${({ theme }) => theme.spacing(6)}px;

  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
    display: inline-flex;
  }
`

const CloseButton = styled(IconButton)`
  font-size: ${({ theme }) => theme.spacing(6)}px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
`

const IMG = styled.img`
  position: absolute;
  top: ${({ theme }) => theme.spacing(2.5)}px;
  left: ${({ theme }) => theme.spacing(2.5)}px;
  width: 100px;
  z-index: 10;
`

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  outline: 0;
  background-color: ${({ theme }) => theme.palette.common.white};
`

const InnerWrapper = styled.div`
  position: absolute;
  top: 102px;
  left: ${({ theme }) => theme.spacing(4)}px;
  right: ${({ theme }) => theme.spacing(4)}px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  outline: 0;
  background-color: ${({ theme }) => theme.palette.common.white};
  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    overflow-y: scroll;
    overflow-x: hidden;
  }
`

const MenuWrapper = styled(MenuList)`
  display: flex;
  flex-direction: column;
  width: fit-content;
  padding: ${({ theme }) => `${theme.spacing(4)}px ${theme.spacing(6)}px`};
  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    padding-left: 0;
    padding-bottom: 0;
  }
`

const MenuBTN = styled(props => <MenuItem component={GatsbyLink} {...props} />)`
  padding: ${({ theme }) => `${theme.spacing(2)}px ${theme.spacing(4)}px`};
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 115%;
  letter-spacing: 0.1rem;
  justify-content: flex-start;
`

const ChildBTN = styled(MenuBTN)`
  font-weight: 200;
  padding-left: ${({ theme }) => `${theme.spacing(8)}px`};
`

const Footer = styled.div`
  margin-top: auto;
  width: 100%;
`
const Contact = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: ${({ theme }) => `0 ${theme.spacing(2)}px`};
`

const A = styled(Link)`
  margin: ${({ theme }) => theme.spacing(1)}px;
  font-size: 125%;
`

const MobileMenu: React.FC = () => {
  const menu = useMainMenu()
  const { email, number, formatted } = { ...(useContactInfo() || {}) }
  const [open, { handleOpen, handleClose }] = useBoolState()

  return (
    <>
      <MenuButton
        color="primary"
        edge="end"
        children={<MenuRoundedIcon fontSize="inherit" />}
        onClick={handleOpen}
        aria-label="open-menu"
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="open-menu"
        aria-describedby="mobile-menu"
      >
        <Slide in={open}>
          <Wrapper>
            <IMG src={Logo} alt="logo" className="logo" />
            <CloseButton
              aria-label="close-menu"
              color="primary"
              onClick={handleClose}
              children={<CloseRoundedIcon fontSize="inherit" />}
            />

            <InnerWrapper>
              <Divider variant="middle" />
              <MenuWrapper>
                {menu?.map(menuItem => {
                  const hasChildren =
                    menuItem?.childItems?.nodes &&
                    menuItem?.childItems?.nodes?.length > 0
                  const childNodes = menuItem?.childItems?.nodes
                  const close =
                    menuItem?.path === "#" ? () => null : handleClose

                  return [
                    <MenuBTN
                      key={menuItem?.id}
                      onClick={close}
                      to={menuItem?.path}
                    >
                      {String(menuItem?.label)}
                    </MenuBTN>,
                    hasChildren
                      ? childNodes?.map((childItem, i) => {
                          const length =
                            Number(menuItem?.childItems?.nodes?.length) - 1
                          const end = length === i

                          return [
                            <ChildBTN
                              key={childItem?.id}
                              onClick={handleClose}
                              to={childItem?.path}
                            >
                              {String(childItem?.label)}
                            </ChildBTN>,
                            end ? <Spacer minHeight={12} /> : null,
                          ]
                        })
                      : null,
                  ]
                })}
              </MenuWrapper>
              <Footer>
                <Contact>
                  {number ? (
                    <A color="primary" href={formatted?.number?.phoneUri}>
                      {formatted?.number?.nicePhone}
                    </A>
                  ) : null}
                  {email ? (
                    <A color="primary" href={formatted?.email?.emailUri}>
                      {email}
                    </A>
                  ) : null}
                </Contact>
                <Divider />
                <Spacer minHeight={16} />
                <Center>
                  <MDDDLink />
                </Center>
                <Spacer minHeight={16} />
              </Footer>
            </InnerWrapper>
          </Wrapper>
        </Slide>
      </Modal>
    </>
  )
}

export default MobileMenu
