import React from "react"
import styled from "styled-components"
import useContactInfo from "../../hooks/useContactInfo"
import Center from "../base/layout/center"
import Spacer from "../base/layout/spacer"
import MDDDLink from "../base/mdddLink"
import ContactData from "./contactData"
import Menu from "./menu"
import SocialMenu from "./socialMenu"
import Text from "./text"

const FooterBar = styled.footer`
  display: flex;
  flex-direction: column;

  background-color: ${({ theme }) => theme.palette.secondary.main};
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  padding: ${({ theme }) => theme.spacing(4)}px;
  padding-bottom: ${({ theme }) => theme.spacing(2)}px;
`

const Extra = styled(props => <Text component="div" {...props} />)`
  padding-left: ${({ theme }) => theme.spacing(4)}px;
`

const FooterMain = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  > * {
    margin-right: auto;
    & :last-child {
      margin-right: 0;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    > * {
      margin-top: ${({ theme }) => theme.spacing(4)}px;
      & :first-child {
        margin-right: 0;
      }
    }
    padding: ${({ theme }) => theme.spacing(1)}px;
    padding-top: ${({ theme }) => theme.spacing(4)}px;
  }
`

const Footer: React.FC = () => {
  const { extra } = { ...(useContactInfo() || {}) }
  return (
    <FooterBar>
      <FooterMain>
        <Menu />
        <ContactData />
        <Extra dangerouslySetInnerHTML={{ __html: String(extra) }} />
      </FooterMain>
      <Spacer minHeight={16} />
      <Center>
        <SocialMenu />
      </Center>
      <Spacer minHeight={8} />
      <Center>
        <MDDDLink color="inherit" />
      </Center>
    </FooterBar>
  )
}

export default Footer
