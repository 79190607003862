import styled from "styled-components"
import { heightTransition } from "../../theme/globalStyles"

interface SpacerProps {
  trigger: boolean
}

const Spacer = styled.div<SpacerProps>`
  height: ${({ theme, trigger }) =>
    trigger ? theme.spacing(20) : theme.spacing(12)}px;
  ${heightTransition}
`

export default Spacer
