import MenuItem from "@material-ui/core/MenuItem"
import MenuList from "@material-ui/core/MenuList"
import { Link as GatsbyLink } from "gatsby"
import React from "react"
import styled from "styled-components"
import useFooterMenu from "../../hooks/useFooterMenu"

const MenuWrapper = styled(MenuList)`
  display: flex;
  flex-direction: column;
  width: fit-content;
`

const MenuBTN = styled(props => <MenuItem component={GatsbyLink} {...props} />)`
  padding: ${({ theme }) => `${theme.spacing(1.5)}px ${theme.spacing(4)}px`};
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  font-size: 115%;
  letter-spacing: 0.1rem;
  justify-content: flex-start;
`

const Menu: React.FC = () => {
  const menu = useFooterMenu()
  return (
    <MenuWrapper>
      {menu?.map(menuItem => (
        <MenuBTN key={menuItem?.id} to={menuItem?.path}>
          {menuItem?.label}
        </MenuBTN>
      ))}
    </MenuWrapper>
  )
}

export default Menu
